<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 00:12:39
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 12:45:35
-->

<template>
  <el-row class="container">
    <el-col :span="14">
      <button id="login-button">隐藏式</button>
    </el-col>
    <el-col :span="10">
      <div class="header">
        <div class="title">欢迎登录</div>
        <div class="tips">亮庭管家</div>
      </div>
      <div class="body">
        <el-form ref="form" :model="form" label-position="top" :rules="rules" class="login-from">
          <el-tabs v-model="form.login_type" @tab-change="tabChange">
            <el-tab-pane label="密码登录" name="0"></el-tab-pane>
            <el-tab-pane label="短信登录" name="1"></el-tab-pane>
          </el-tabs>

          <el-form-item :label="hint.accountLabel">
            <el-input
              v-model="form.accountOrPhone"
              :placeholder="hint.accountOrPhone"
              size="large"
              @input="onChange"
            ></el-input>
          </el-form-item>
          <el-form-item :label="hint.pwLabel">
            <el-input
              :show-password="form.login_type == 0"
              :placeholder="hint.pwdOrSmsCode"
              v-model="form.pwdOrSmsCode"
              size="large"
              @input="onChange"
            ></el-input>

            <el-button
              class="btn-sms"
              text
              type="text"
              v-if="form.login_type == 1"
              @click="onSms"
              :disabled="btnSms.disabled"
            >{{ btnSms.text }}</el-button>
          </el-form-item>
          <el-form-item label>
            <el-checkbox v-model="form.remember">一周内自动登录</el-checkbox>
          </el-form-item>

          <el-form-item>
            <el-button
              style="width: 100%; margin-top: 20px"
              type="primary"
              :loading="loading"
              :disabled="disabled"
              @click="onSubmit"
              size="large"
              round
            >立即登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import md5 from "js-md5";
import Cookies from "js-cookie";
import common_api from "@/http/common_api";
import center_api from "../../http/center_api";

export default {
  name: "login",
  data() {
    return {
      loading: false,
      disabled: true,
      form: {
        login_type: 0,
        accountOrPhone: "",
        pwdOrSmsCode: "",
        remember: false,
      },
      rules: {
        accountOrPhone: [
          { required: true, message: "请输入", trigger: "input" },
        ],
        pwdOrSmsCode: [{ required: true, message: "请输入", trigger: "input" }],
      },
      hint: {
        accountOrPhone: "账号名/手机号",
        pwdOrSmsCode: "请输入登录密码",
        accountLabel: "账号",
        pwLabel: "密码",
      },
      btnSms: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  created: function () {
    this.form.accountOrPhone = localStorage.getItem(
      this.$cfg.LOGIN_ACCOUNT_KEY
    );
    var _this = this;
    //参数
    common_api.get_config().then((res) => {
      window
        .vaptcha({
          vid: res.data.vaptcha_vid,
          mode: "invisible",
        })
        .then(function (VAPTCHAObj) {
          _this.vaptcha = VAPTCHAObj;
          // 渲染验证组件
          VAPTCHAObj.render();

          // 验证成功进行后续操作
          VAPTCHAObj.listen("pass", function () {
            _this.btnSms.disabled = true;
            var serverToken = VAPTCHAObj.getServerToken();
            _this.$http
              .post("common/sms/captcha", {
                mobile: _this.form.accountOrPhone,
                type: 2,
                token: serverToken.token,
                server: serverToken.server,
                scene: 0,
              })
              .then((res) => {
                if (res.code == 0) {
                  _this.$message({
                    type: "success",
                    message: res.msg,
                  });
                  //发送成功，倒计时
                  _this.btnSms.disabled = true;
                  var sec = 60;
                  var t = setInterval(() => {
                    sec--;
                    if (sec <= 0) {
                      clearInterval(t);
                      _this.btnSms.disabled = false;
                      _this.btnSms.text = "获取验证码";
                      VAPTCHAObj.reset();
                    } else {
                      _this.btnSms.text = `${sec}s`;
                    }
                  }, 1000);
                } else {
                  VAPTCHAObj.reset();
                }
              });
          });

          VAPTCHAObj.listen("close", function () {
            console.log("验证弹窗关闭触发");
            // 验证弹窗关闭触发
            VAPTCHAObj.reset();
          });
        });
    });
  },
  mounted() {},
  methods: {
    /**
     * 登录
     */
    onSubmit: async function () {
      this.loading = true;
      var postModel = Object.assign({}, this.form);
      var _router = this.$router;

      if (this.form.login_type == 0) {
        var salt = await this.getSalt();
        postModel.pwdOrSmsCode = md5(this.form.pwdOrSmsCode + salt);
      }
      //记录登录账号
      localStorage.setItem(
        this.$cfg.LOGIN_ACCOUNT_KEY,
        this.form.accountOrPhone
      );
      this.$http
        .post("seller/v1/login", postModel)
        .then((res) => {
          if (res.code == 0) {
            //存储token
            Cookies.set(this.$cfg.TOKEN, res.data.token);
            //获取个人权限接口数据
            center_api.get_apis().then((res) => {
              if (res.code == 0) {
                if (res.data) {
                  localStorage.setItem(
                    this.$cfg.API_STORE_KEY,
                    JSON.stringify(res.data)
                  );
                  _router.push({ name: "dashboard" });
                }
              }
            });
          } else {
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
        });
    },

    /**
     * 获取密码盐
     */
    getSalt() {
      return new Promise((resolve, reject) => {
        this.$http
          .get(
            "seller/v1/login/salt?accountOrPhone=" + this.form.accountOrPhone
          )
          .then((res) => {
            if (res.code == 0) {
              return resolve(res.data.salt);
            } else {
              return reject(res.msg);
            }
          });
      });
    },

    /**
     * 登陆类型更改
     */
    tabChange(val) {
      this.form.pwdOrSmsCode = "";
      if (val == 1) {
        if (!this.$utils.is_phone(this.form.accountOrPhone)) {
          this.form.accountOrPhone = "";
        }

        this.hint.accountOrPhone = "请输入手机号码";
        this.hint.pwdOrSmsCode = "请输入验证码";
        this.hint.accountLabel = "手机";
        this.hint.pwLabel = "验证码";
      } else {
        this.hint.accountOrPhone = "账号名/手机号";
        this.hint.pwdOrSmsCode = "请输入登录密码";
        this.hint.accountLabel = "账号";
        this.hint.pwLabel = "密码";
      }
    },
    /**
     * 输入框更改
     */
    onChange: function () {
      this.disabled = !(this.form.accountOrPhone && this.form.pwdOrSmsCode);
    },
    /**
     * 获取短信验证码
     */
    onSms() {
      if (this.form.accountOrPhone) {
        if (this.$utils.is_phone(this.form.accountOrPhone)) {
          this.vaptcha.validate();
          return;
        }
      }
      this.$message({ type: "error", message: "请输入手机号码" });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  background: url("../../assets/dlbox.svg") no-repeat;
  background-size: auto 100%;
  background-position: 0 center;
}
.container .el-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  align-items: flex-end;
}
.header .title {
  font-size: 30px;
  color: #111;
  margin-right: 10px;
}
.header .tips {
  font-size: 20px;
}
.body {
  width: 400px;
  height: 500px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: var(--el-box-shadow-light);
  margin-top: 40px;
  padding-top: 40px;
}

.login-from {
  width: 70%;
  margin: 0 auto;
}
.prompt {
  font-size: 12px;
  color: #666;
}

.btn-sms {
  position: absolute;
  right: 10px;
}
</style>